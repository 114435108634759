import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCheck } from '@fortawesome/free-solid-svg-icons';

export class ValueViewEdit extends Component {

    constructor(props) {
        super(props);

        this.state = { value: props.value, editMode: false };

        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.save = this.save.bind(this);
        this.valueChanged = this.valueChanged.bind(this);
    }

    render() {
        if (this.state.editMode) {
            return (
                <div className="row">
                    <div className="col-10">
                        <input className="form-control" type='text' value={this.state.value || ''} onChange={this.valueChanged} />
                    </div>
                    <div className="col-2">
                        <FontAwesomeIcon icon={faCheck} onClick={this.save} />
                    </div>
                </div>
            );
        }
        return (
            <div className="row">
                <div className="col-10">
                    {this.state.value || "<unnamed>"}
                </div>
                <div className="col-2">
                    <FontAwesomeIcon icon={faPencilAlt} onClick={this.toggleEditMode} />
                </div>
            </div>
        );
    }

    save() {
        this.props.valueChanged(this.state.value);
        this.toggleEditMode();
    }

    valueChanged(e) {
        this.setState({ value: e.target.value });
    }

    toggleEditMode() {
        this.setState({ editMode: !this.state.editMode });
    }
}