import React, { Component } from 'react';
import { API } from './API';
import { FileTile } from './FileTile';
import { GetFileType, GetQueryString, SplitTags } from './HelperFunctions';
import { NavHack } from './NavHack';
import { FileUpload } from './FileUpload';
import { TagView } from './TagView';
import { FileInfo } from './FileInfo';
import { SearchManager } from './SearchManager';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        var params = GetQueryString();
        var currentTags = [];
        var order = "Weighted";
        var display = "grid";
        if (params["tags"]) {
            var tags = params["tags"].split(",");
            tags.forEach(tag => {
                var updatedTag = decodeURI(tag);
                // if(updatedTag.includes(" ")) {
                //     updatedTag = `"${updatedTag}"`;
                // }
                currentTags.push(updatedTag);
            });
        }
        if (params["order"]) {
            order = params["order"];
        }
        if(params["display"]) {
            display = params["display"];
        }

        this.state = { 
            files: [], 
            page: props.page, 
            totalPages: 1, 
            tags: [], 
            searchTags: currentTags.join(" "), 
            totalFiles: 0, 
            orderBy: order, 
            uploadLink: "", 
            selected: [],
            navToLink: null,
            updateTaskRunning: null,
            selectedCollection: null,
            allCollections: [],
            newCollectionName: "",
            display: display,
            displayCollectionInfo: null
        };
        this.searchTagsChanged = this.searchTagsChanged.bind(this);
        this.tagClicked = this.tagClicked.bind(this);
        this.handleFileResults = this.handleFileResults.bind(this);
        this.deletePage = this.deletePage.bind(this);
        this.orderChanged = this.orderChanged.bind(this);
        this.uploadLinkChanged = this.uploadLinkChanged.bind(this);
        this.uploadLinkClicked = this.uploadLinkClicked.bind(this);
        this.addCollection = this.addCollection.bind(this);
        this.startConversionJob = this.startConversionJob.bind(this);
        this.addToCollectionClicked = this.addToCollectionClicked.bind(this);
        this.displayChanged = this.displayChanged.bind(this);
    }

    render() {
        let files = this.state.files.map(f => (<FileTile wide={this.state.display === "list"} file={f} creds={this.props.creds} key={f.fileGuid}
            isSelected={this.state.selected.includes(f.fileGuid)} singleClick={ev => this.fileTileSingleClick(ev, f)} doubleClick={ev => this.fileTileDoubleClick(ev, f)} />));

        return (
            <div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <div>
                            <input className="form-control" type="text" onChange={this.searchTagsChanged} value={this.state.searchTags} />
                            {/*(<button className="btn btn-secondary" onClick={this.buttonClicked}>{this.props.buttonText}</button>)*/}
                        </div>
                        <div className="row">
                            {this.renderTagList()}
                            {this.renderSetCollectionCover()}
                            { this.state.displayCollectionInfo !== null ? (<FileInfo fileInfo={this.state.displayCollectionInfo} creds={this.props.creds} />) : null }
                        </div>
                    </div>
                    <div className="col-12 col-md-9">
                        {this.renderPagination()}
                        {this.renderOptionBar()}
                        <div className="row">
                            {files}
                        </div>
                        {this.renderPagination()}
                        <div className='row'>
                            <div className='col-12' style={{ marginLeft: "-13px", marginRight: "-13px" }}>
                                <FileUpload Creds={this.props.creds} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                Add from link:
                            </div>
                            <div className="col-7">
                                <input className="form-control" type="text" onChange={this.uploadLinkChanged} value={this.state.uploadLink} />
                            </div>
                            <div className="col-2">
                                <button className="btn btn-primary" onClick={this.uploadLinkClicked}>Add</button>
                            </div>
                        </div>
                        <div>
                            <div className="col-12">
                                <div className='row'>
                                    <div className='col'>
                                        <input type="text" className='form-control' value={this.state.newCollectionName} onChange={e => this.setState({ newCollectionName: e.target.value })} />
                                    </div>
                                    <div className='col'>
                                        <button className="btn btn-primary" onClick={this.addCollection}>Add Collection</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {this.renderConversionButton()}
                        </div>
                        {this.renderLogsButton()}
                    </div>
                </div>
                {this.state.navToLink != null ? (<NavHack link={this.state.navToLink} />) : null}
            </div>
        );
    }

    renderLogsButton() {
        if(this.props.creds.UserName === "randomeskimo" || this.props.creds.UserName === "local") {
            return (
                <div>
                    <button className="btn btn-primary" onClick={() => this.goToLogs()}>View Logging</button>
                </div>
            );
        }
        return null;
    }

    renderPagination() {
        return (
            <div className="row" style={{ marginBottom: "10px" }}>
                <div className="col">
                    <button className="btn btn-primary" disabled={this.state.page === 1} onClick={() => this.loadImages(this.state.page - 1)}>Prev</button>
                </div>
                <div className="col" style={{ display: "flex", justifyContent: "center" }}>
                    Page {this.state.page}/{this.state.totalPages} ({this.state.totalFiles} files)
                </div>
                <div className="col" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button className="btn btn-primary" disabled={this.state.page === this.state.totalPages} onClick={() => this.loadImages(this.state.page + 1)}>Next</button>
                </div>
            </div>
        );
    }

    renderConversionButton() {
        if(this.state.updateTaskRunning === "false") {
            return (<div className='col-12'>
                <input type="button" value="Start Conversion job" className="btn btn-secondary" onClick={this.startConversionJob} />
            </div>);
        }
        else if(this.state.updateTaskRunning === "allconverted") {
            return (<div>All files are V2!</div>);
        }
        else if (this.state.updateTaskRunning != null && this.state.updateTaskRunning.startsWith("true")) {
            var parts = this.state.updateTaskRunning.split(":");
            if(parts.length === 2)
                return (<div>Update Task is running, {parts[1]} files left</div>);
            return (<div>Update Task is running</div>);
        }
    }

    renderOptionBar() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="form-inline mr-2">
                        <div className="form-group">
                            <select className="form-control" defaultValue={this.state.orderBy} onChange={this.orderChanged}>
                                <option value="NewestFirst">Newest First</option>
                                <option value="OldestFirst">Oldest First</option>
                                <option value="RatingBestFirst">Best First</option>
                                <option value="RatingWorstFirst">Worst First</option>
                                <option value="Weighted">Weighted</option>
                                {this.state.searchTags.includes("collection:") ? (<option value="CollectionOrder">Collection Order</option>) : null}
                            </select>
                        </div>
                        <div className='form-group'>
                            <select className='form-control' defaultValue={this.state.display} onChange={this.displayChanged}>
                                <option value="grid">Grid</option>
                                <option value="list">List</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-danger" onClick={this.deletePage}>Delete Page</button>
                        </div>
                        {this.state.allCollections.length > 0 ? (<div className='form-group'>
                            <select className='form-control' defaultValue={this.state.selectedCollection} onChange={e => this.setState({ selectedCollection: e.target.value })}>
                                {this.state.allCollections.map(c => (<option value={c.fileGuid}>{c.displayName}</option>))}
                            </select>
                            <button className='btn btn-outline-secondary' enabled={this.state.selected.length > 0} onClick={this.addToCollectionClicked}>Add to collection</button>
                        </div>) : null}
                    </div>
                </div>
            </div>
        );
    }

    renderTagList() {
        return this.state.tags.map(tag => (<TagView tagClicked={() => this.tagClicked(tag)} key={tag.tag} tag={tag} />));
    }

    async componentDidMount() {
        this.loadImages(this.state.page);
        this.loadTags();
        this.loadIsUpdateTaskRunning();
        this.loadAllCollections();
    }

    async loadIsUpdateTaskRunning() {
        var isRunning = await API.GetIsUpdateTaskRunning(this.props.creds);
        this.setState({ updateTaskRunning: isRunning });
    }

    async loadAllCollections() {
        var collections = await API.GetAllCollections(this.props.creds);
        if(collections)
            this.setState({ allCollections: collections, selectedCollection: collections.length > 0 ? collections[0].fileGuid : null });
    }

    async loadImages(page) {
        // if (this.state.searchTags.length === 0) {
        //     API.SearchFiles(this.props.creds, [], page, this.state.orderBy, this.handleFileResults);
        // }
        // else {
        //     API.SearchFiles(this.props.creds, SplitTags(this.state.searchTags), page, this.state.orderBy, this.handleFileResults);
        // }
        var results = await SearchManager.MakeSearch(this.props.creds, this.state.searchTags, page, this.state.orderBy);
        this.handleFileResults(results);
    }

    handleFileResults(fileDetails) {
        this.setState({ 
            page: fileDetails.currentPage, 
            files: fileDetails.files, 
            totalPages: fileDetails.totalPages, 
            totalFiles: fileDetails.totalFiles, 
            displayCollectionInfo: fileDetails.collection, 
            filesPerPage: fileDetails.filesPerPage
        });
        if (this.props.onPageChanged) {
            this.props.onPageChanged(fileDetails.currentPage);
        }
        console.log(fileDetails);
    }

    loadTags() {
        if (this.state.searchTags.length === 0) {
            API.GetTagsForUser(this.props.creds, tags => {
                this.setState({ tags: tags });
            });
        }
        else {
            API.GetTagsForSearch(this.props.creds, SplitTags(this.state.searchTags), tags => {
                this.setState({ tags: tags });
            });
        }
    }

    searchTagsChanged(e) {
        this.updateSearchTags(e.target.value);
    }

    tagClicked(tag) {
        var newSearch = this.state.searchTags;
        if (newSearch.length === 0) {
            newSearch = tag.tag;
        }
        else {
            newSearch += " " + tag.tag;
        }
        this.updateSearchTags(newSearch);
    }

    updateSearchTags(newSearchText) {

        //update the path
        this.setState({ searchTags: newSearchText }, () => {
            this.loadImages(1);
            this.loadTags();
            this.updateUrl();
        });
    }

    deletePage() {
        if (window.confirm("Are you sure you want to delete this whole page?")) {
            let deleteCount = 0;
            this.state.files.forEach(file => {
                API.AddTagToFile(this.props.creds, file.fileGuid, "delete", r => {
                    deleteCount++;
                    if (deleteCount === this.state.files.length) {
                        this.loadImages(1);
                        this.loadTags();
                    }
                })
            });
        }
    }

    orderChanged(e) {
        this.setState({ orderBy: e.target.value }, () => {
            this.loadImages(1);
            this.updateUrl();
        });
        
    }

    displayChanged(e) {
        this.setState({ display: e.target.value }, () => {
            this.updateUrl();
        });
    }

    updateUrl() {
        var tags = SplitTags(this.state.searchTags).map(t => encodeURI(t)).join(',');
        var parts = window.location.href.split("/");
        var newUrl = `${parts[0]}//${parts[2]}`
        if (tags.length > 0) {
            newUrl = `${newUrl}?tags=${tags}&order=${this.state.orderBy}&display=${this.state.display}`;
        }
        else {
            newUrl = `${newUrl}?order=${this.state.orderBy}&display=${this.state.display}`;
        }
        window.history.pushState("", "", newUrl);
    }

    uploadLinkChanged(e) {
        this.setState({ uploadLink: e.target.value });
    }

    uploadLinkClicked() {
        API.AddFileFromLink(this.props.creds, this.state.uploadLink, () => {
            this.setState({ page: 1 });
            this.loadImages(1);
            this.loadTags();
        });
        this.setState({ uploadLink: "" });
    }

    addCollection() {
        if(this.state.newCollectionName) {
            API.AddCollection(this.props.creds, this.state.newCollectionName, () => {
                console.log("Success");
                this.setState({ newCollectionName: "" });
                this.updateSearchTags(this.state.searchTags);
                this.loadAllCollections();
            });
        }
    }

    fileTileSingleClick(ev, file) {
        ev.stopPropagation();
        if (ev.ctrlKey) {
            let newState = { selected: this.state.selected.slice() };
            if (!newState.selected.includes(file.fileGuid)) {
                newState.selected.push(file.fileGuid)
            }
            else {
                newState.selected.splice(newState.selected.indexOf(file.fileGuid, 1));
            }
            console.log(newState);
            this.setState(newState)
        }
        else {
            this.setState({ selected: [ file.fileGuid ] });
        }
    }

    fileTileDoubleClick(ev, file) {
        if(file.type === "Collection") {
            var updatedTags = `collection:"${file.displayName}"`;
            this.updateSearchTags(updatedTags);
        }
        else {
            SearchManager.SetDisplayIndex((this.state.page - 1) * this.state.filesPerPage + this.state.files.indexOf(file));
            this.setState({ navToLink: `/file/${file.fileGuid}` });
        }
    }

    startConversionJob() {
        API.StartConversionJob(this.props.creds);
        this.setState({ updateTaskRunning: "true" });
        this.loadIsUpdateTaskRunning();
    }

    async addToCollectionClicked() {
        console.log(this.state.selectedCollection);
        console.log(this.state.selected);
        if(this.state.selectedCollection != null && this.state.selected.length > 0) {
            var result = await API.AddFilesToCollection(
                this.props.creds, 
                this.state.selectedCollection, 
                this.state.selected
            );
            console.log(result);
            this.setState({ selected: [] });
        }
    }

    goToLogs() {
        this.setState({ navToLink: `/logging` });
    }

    renderSetCollectionCover() {
        console.log(this.state.selected);
        if(this.state.displayCollectionInfo !== null && this.state.selected.length === 1 && this.getSelectedFileType() === "image") {
            return (<div>
                <input type="button" value="Set as cover" className='btn btn-outline-secondary' onClick={() => this.setSelectedImageAsCollectionCover()} />
            </div>)
        }
        return null;
    }

    getSelectedFileType() {
        if(this.state.selected.length === 1) {
            var file = this.state.files.filter(it => it.fileGuid === this.state.selected[0]);
            if(file.length > 0) {
                return GetFileType(file[0].fileExtension);
            }
        }
        return null;
    }

    setSelectedImageAsCollectionCover() {
        API.SetCollectionCoverImage(this.props.creds, this.state.displayCollectionInfo.fileGuid, this.state.selected[0]);
    }
}