import React, { useState } from 'react';
import { API } from './API';
import { useHistory } from "react-router-dom";

var _setFileUploadHistory;
var _fileUploadHistory;

export function FileUpload(props) {
    const [fileUploadHistory, setFileUploadHistory] = useState([ ]);
    _setFileUploadHistory = setFileUploadHistory;
    _fileUploadHistory = fileUploadHistory;
    
    return (
        <div style={{padding: "10px", marginTop: "10px", marginBottom: "10px", backgroundColor: "lightgrey" }}>
            <h4>Upload File</h4>
            <input type="file" onChange={OnFileUploadChange} accept="*" multiple />
            {fileUploadHistory.length > 0 ? (<div>Uploads</div>) : null}
            <ul className='list-group'>
                {fileUploadHistory.map(file => (<UploadingFile key={file.file.name} file={file} />))}
            </ul>
        </div>
    );

    function OnFileUploadChange(e) {
        var chunkSize = 1024 * 1024; //1mb chunks
        const fileUploadObjects = ConvertFileList(e.target.files).map(f => ({ file: f, uploaded: "no", guid: CreateGuid() }));

        _setFileUploadHistory(_fileUploadHistory.concat(fileUploadObjects));

        fileUploadObjects.forEach(async file => {
            var size = file.file.size;
            var parts = Math.ceil(size / chunkSize);
            var currentPart = 1;
            var currentIndex = 0;
            var result = null;
            while(currentIndex < size) {
                var blob = file.file.slice(currentIndex, currentIndex + chunkSize);
                currentIndex += blob.size;

                var b64Data = await BlobToBase64(blob);

                var uploadObject = {
                    FileName: file.file.name,
                    GUID: file.guid,
                    FileData: b64Data,
                    TotalParts: parts,
                    Part: currentPart
                };

                result = await API.UploadFileChunk(props.Creds, uploadObject);
                if(result !== "success") {
                    console.log(result);
                    break;
                }

                file.uploaded = `${Math.round(currentPart / parts * 100)}%`;
                _setFileUploadHistory(_fileUploadHistory.slice());
                currentPart++;
            }

            if(result === "success") {
                file.uploaded = "yes";
                _setFileUploadHistory(_fileUploadHistory.slice());
            }
        });
    }

    function ConvertFileList(FileList) {
        var files = [];
        for(var i = 0;i < FileList.length;++i)
            files.push(FileList[i]);
        return files;
    }
}

// var fileUploads = [];

// export function useFileUploadList() {
    
// }

function UploadingFile(props) {
    const history = useHistory();

    function viewButtonClicked() {
        history.push(`/file/${props.file.guid}`);
    }

    var status = (<span>...{props.file.uploaded}</span>);
    if(props.file.uploaded === "yes")
        status = (<input type="button" className='btn btn-success btn-sm' value="View" onClick={viewButtonClicked} />);
    else if(props.file.uploaded === "failed")
        status = (<span style={{color: "red"}}>...failed</span>);
    else if(props.file.uploaded === "no")
        status = (<span>...0%</span>);
    return (
        <li className='list-group-item'>
            <span>{props.file.file.name}</span>
            {status}
        </li>
    );
}

function CreateGuid() {  
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> c / 4).toString(16)
  )
 } 

 function BlobToBase64(blob) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function() {
            var dataUrl = reader.result;
            var base64 = dataUrl.split(',')[1];
            resolve(base64);
        };
        reader.readAsDataURL(blob);
    });
}
