import React, { Component } from 'react';
import { ErrorMessage } from './ErrorMessage';
import { API } from './API';

export class Login extends Component {

    constructor(props) {
        super(props);
        this.state = { username: "", password: "", error: null };

        this.loginButtonClicked = this.loginButtonClicked.bind(this);
        this.usernameChanged = this.usernameChanged.bind(this);
        this.passwordchanged = this.passwordchanged.bind(this);
    }

    render() {
        return (
            <div className="login-form">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-10 col-12">
                        <form onSubmit={this.loginButtonClicked}>
                            <h2>Login</h2>
                            {this.state.error != null && (<ErrorMessage message={this.state.error} />)}
                            <div className="form-group">
                                <label htmlFor="txtUsername">Username:</label>
                                <input type="text" id="txtIsername" value={this.state.username} className="form-control" onChange={this.usernameChanged} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="txtPassword">Password:</label>
                                <input type="password" id="txtPassword" value={this.state.password} className="form-control" onChange={this.passwordchanged} />
                            </div>
                            <input type="button" value="Login" className="btn btn-primary btn-block" onClick={this.loginButtonClicked} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    loginButtonClicked(e) {
        e.preventDefault();
        if (this.state.username === null || this.state.username.length === 0) {
            this.setState({ error: 'Please provide a username' });
            return;
        }

        if (this.state.password === null || this.state.password.length === 0) {
            this.setState({ error: 'Please provide a password' });
            return;
        }

        API.Authenticate(this.state.username, this.state.password, r => {
            if (r) {
                this.props.onlogin({ UserName: this.state.username, Password: this.state.password });
            }
            else {
                this.setState({ error: 'Invalid username or password' });
            }
        });
    }

    usernameChanged(e) {
        this.setState({ username: e.target.value, error: null });
    }

    passwordchanged(e) {
        this.setState({ password: e.target.value, error: null });
    }
}