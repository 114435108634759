import React, { Component } from 'react';
import { API } from './API';
import { GetFileType } from './HelperFunctions'

export class FileTile extends Component {
    constructor(props) {
        super(props);

        this.state = { textFileContent: null };
    }
    
    render() {
        if(this.props.wide)
        {
            return this.renderWide();
        }
        return this.renderDefault();
    }

    renderDefault() {
        let content = null;

        if (this.props.file.type === "Collection") {
            content = (
                <div style={{ fontWeight: "bold", textAlign: "center", overflow: "hidden", wordBreak: "break-word", display: "flex", height: "100%", position: "relative" }}>
                    {/*<div style={{position: "absolute", display: "flex", height: "100%", width: "100%"}}>
                        <div style={{ display: "flex", margin: "auto", textShadow: "1px 1px 3px lightGrey" }}>{this.props.file.displayName}</div>
            </div>*/}
                    <div style={{ display: "flex", height: "100%" }}>
                        <img style={{ maxWidth: "100%", display: "flex", margin: "auto" }} src={"/api/files/small/" + this.props.creds.UserName + "/" + this.props.creds.Password + "/" + this.props.file.fileGuid} alt={this.props.file.displayName || this.props.file.fullFileName} />
                    </div>
                </div>
            );
        }
        else {
            let fileType = GetFileType(this.props.file.fileExtension);
            if (fileType === "image") {
                content = (<div style={{ display: "flex", height: "100%"}}>
                        <img style={{ maxWidth: "100%", display: "flex", margin: "auto" }} src={"/api/files/small/" + this.props.creds.UserName + "/" + this.props.creds.Password + "/" + this.props.file.fileGuid} alt={this.props.file.displayName || this.props.file.fullFileName} />
                    </div>);
            }
            else if (fileType === "text") {
                content = (
                    <div style={{ width: "100%", height: "100%" }}>
                        <div style={{ fontWeight: "bold", textAlign: "center" }}>{this.props.file.displayName || this.props.file.fullFileName}</div>
                        <div style={{ alignContent: "left", wordBreak: "break-word", overflow: "hidden" }}>
                            {this.state.textFileContent || "loading..."}
                        </div>
                    </div>
                );
                if (this.state.textFileContent === null) {
                    this.loadTextContent();
                }
            }
            else if (fileType === "video") {
                content = (
                    <div style={{ width: "100%", height: "100%", position: "relative" }}>
                        <div style={{position: "absolute", display: "flex", height: "100%", width: "100%"}}>
                            <svg style={{ display: "flex", margin: "auto" }} width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.97 22C17.4928 22 21.97 17.5228 21.97 12C21.97 6.47715 17.4928 2 11.97 2C6.44712 2 1.96997 6.47715 1.96997 12C1.96997 17.5228 6.44712 22 11.97 22Z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8.73999 12.2299V10.5599C8.73999 8.47988 10.21 7.62988 12.01 8.66988L13.46 9.50988L14.91 10.3499C16.71 11.3899 16.71 13.0899 14.91 14.1299L13.46 14.9699L12.01 15.8099C10.21 16.8499 8.73999 15.9999 8.73999 13.9199V12.2299Z" stroke="#FFFFFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div style={{ display: "flex", height: "100%" }}>
                            <img style={{ maxWidth: "100%", display:"flex", margin: "auto" }} src={"/api/files/small/" + this.props.creds.UserName + "/" + this.props.creds.Password + "/" + this.props.file.fileGuid} alt={this.props.file.displayName || this.props.file.fullFileName} />
                        </div>
                    </div>
                );
            }
        }

        if (content === null) {
            return (<div className="col-3" style={{ overflow: "hidden" }}>{this.props.file.fullFileName}</div>);
        }

        var dv = (
            <div style={{ width: "100%", height: "100%" }}>
                {content}
            </div>
        );

        return (
            <div className="col-6 col-md-3" style={{ display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: this.props.isSelected ? "lightblue" : "lightgray", border: "solid 2px white", paddingTop: "16px", paddingBottom: "16px" }}
                onClick={this.props.singleClick} onDoubleClick={this.props.doubleClick}>
                {dv}
                <div style={{ fontWeight: "bold", marginTop: "auto", marginRight: "auto", marginLeft:"auto" }}>{this.props.file.displayName || ""}</div>
            </div>
        );
    }

    renderWide() {
        let info = null;
        let content = null;
        var headingCol = "col-2";
        var dataCol = "col-10";

        if (this.props.file.type === "Collection") {
            content = (
                <div style={{ fontWeight: "bold", textAlign: "center", overflow: "hidden", wordBreak: "break-word", display: "flex", height: "100%", position: "relative" }}>
                    <div style={{ display: "flex", height: "100%" }}>
                        <img style={{ maxWidth: "100%", display: "flex", margin: "auto" }} src={"/api/files/small/" + this.props.creds.UserName + "/" + this.props.creds.Password + "/" + this.props.file.fileGuid} alt={this.props.file.displayName || this.props.file.fullFileName} />
                    </div>
                </div>
            );
            info = (
                <div className='row'>
                    <div className={headingCol} style={{ fontWeight: "bold" }}>
                        Name:
                    </div>
                    <div className={dataCol}>
                        Collection: {this.props.file.displayName}
                    </div>
                    <div className={headingCol} style={{ fontWeight: "bold" }}>
                        Rating:
                    </div>
                    <div className={dataCol}>
                        {this.props.file.rating || 0}
                    </div>
                </div>
            );
        }
        else {
            let fileType = GetFileType(this.props.file.fileExtension);
            if (fileType === "image") {
                content = (
                    <div style={{ display: "flex", height: "100%"}}>
                        <img style={{ maxWidth: "100%", display: "flex", margin: "auto" }} src={"/api/files/small/" + this.props.creds.UserName + "/" + this.props.creds.Password + "/" + this.props.file.fileGuid} alt={this.props.file.displayName || this.props.file.fullFileName} />
                    </div>
                );
                info = (
                    <div className='row'>
                        <div className={headingCol} style={{ fontWeight: "bold" }}>Name:</div>
                        <div className={dataCol}>
                            {this.props.file.displayName || this.props.file.fullFileName}
                        </div>
                        <div className={headingCol} style={{ fontWeight: "bold" }}>
                            Rating:
                        </div>
                        <div className={dataCol}>
                            {this.props.file.rating || 0}
                        </div>
                        <div className={headingCol} style={{ fontWeight: "bold" }}>
                            Views:
                        </div>
                        <div className={dataCol}>
                            {this.getViews(this.props.file.metadata)}
                        </div>
                    </div>
                );
            }
            else if (fileType === "text") {
                content = (
                    <div style={{ width: "100%", height: "100%" }}>
                        <div style={{ fontWeight: "bold", textAlign: "center" }}>{this.props.file.displayName || this.props.file.fullFileName}</div>
                        <div style={{ alignContent: "left", wordBreak: "break-word", overflow: "hidden" }}>
                            {this.state.textFileContent || "loading..."}
                        </div>
                    </div>
                );
                if (this.state.textFileContent === null) {
                    this.loadTextContent();
                }
            }
            else if (fileType === "video") {
                content = (
                    <div style={{ width: "100%", height: "100%", position: "relative" }}>
                        <div style={{position: "absolute", display: "flex", height: "100%", width: "100%"}}>
                            <svg style={{ display: "flex", margin: "auto" }} width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.97 22C17.4928 22 21.97 17.5228 21.97 12C21.97 6.47715 17.4928 2 11.97 2C6.44712 2 1.96997 6.47715 1.96997 12C1.96997 17.5228 6.44712 22 11.97 22Z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8.73999 12.2299V10.5599C8.73999 8.47988 10.21 7.62988 12.01 8.66988L13.46 9.50988L14.91 10.3499C16.71 11.3899 16.71 13.0899 14.91 14.1299L13.46 14.9699L12.01 15.8099C10.21 16.8499 8.73999 15.9999 8.73999 13.9199V12.2299Z" stroke="#FFFFFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <div style={{ display: "flex", height: "100%" }}>
                            <img style={{ maxWidth: "100%", display:"flex", margin: "auto" }} src={"/api/files/small/" + this.props.creds.UserName + "/" + this.props.creds.Password + "/" + this.props.file.fileGuid} alt={this.props.file.displayName || this.props.file.fullFileName} />
                        </div>
                    </div>
                );
                info = (
                    <div className='row'>
                        <div className={headingCol} style={{ fontWeight: "bold" }}>Name:</div>
                        <div className={dataCol}>
                            {this.props.file.displayName || this.props.file.fullFileName}
                        </div>
                        <div className={headingCol} style={{ fontWeight: "bold" }}>
                            Rating:
                        </div>
                        <div className={dataCol}>
                            {this.props.file.rating || 0}
                        </div>
                        <div className={headingCol} style={{ fontWeight: "bold" }}>
                            Views:
                        </div>
                        <div className={dataCol}>
                            {this.getViews(this.props.file.metadata)}
                        </div>
                        <div className={headingCol} style={{ fontWeight: "bold" }}>
                            Length:
                        </div>
                        <div className={dataCol}>
                            {this.getDuration(this.props.file.metadata)}
                        </div>
                    </div>
                );
            }
        }

        if (content === null) {
            return (<div className="col-12" style={{ overflow: "hidden" }}>{this.props.file.fullFileName}</div>);
        }

        var dv = (
            <div style={{ width: "100%", height: "100%" }}>
                {content}
            </div>
        );

        return (
            <div className='col-12'>
                <div className='row' style={{backgroundColor: this.props.isSelected ? "lightblue" : "lightgray", border: "solid 2px white", paddingTop: "16px", paddingBottom: "16px"}}>
                    <div className='col-6 col-md-9'>
                        {info}
                    </div>
                    <div className="col-6 col-md-3" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
                        onClick={this.props.singleClick} onDoubleClick={this.props.doubleClick}>
                        {dv}
                    </div>
                </div>
            </div>
        );
    }

    getLink() {
        if (this.props.file.type === "Collection") {
            return `/collection/${this.props.file.fileGuid}`;
        }
        else {
            let fileType = GetFileType(this.props.file.fileExtension);
            if (fileType === "image") {
                return "/file/" + this.props.file.fileGuid;
            }
            else if (fileType === "text") {
                return `/file/${this.props.file.fileGuid}`;
            }
            else if (fileType === "video") {
                return `/file/${this.props.file.fileGuid}`;
            }
        }
        return null;
    }

    loadTextContent() {
        API.GetSmallFile(this.props.creds, this.props.file.fileGuid, "text", file => {
            this.setState({ textFileContent: file });
        });
    }

    getViews(metadata) {
        if(metadata) {
            var parsedMetadata = JSON.parse(metadata);
            if(parsedMetadata.views)
                return parsedMetadata.views;
        }
        return 0;
    }

    getDuration(metadata) {
        if(metadata) {
            var parsedMetadata = JSON.parse(metadata);
            if(parsedMetadata.duration !== null && parsedMetadata.duration !== undefined)
            {
                var duration = parsedMetadata.duration;
                console.log(duration);
                var minutes = Math.round(duration / 60);
                var seconds = Math.round(duration - (minutes * 60));
                var strMinutes = `${minutes}`;
                var strSeconds = `${seconds}`;
                if(strMinutes.length < 2)
                    strMinutes = `0${strMinutes}`;
                if(strSeconds.length < 2)
                    strSeconds = `0${strSeconds}`;
                return `${strMinutes}:${strSeconds}`;
            }
        }
        return "?";
    }
}