import React, { useEffect, useState } from "react";
import { API } from "./API";
import { IsStreamableVideo } from "./HelperFunctions";

export function VideoView(props) {
    const [state, setState] = useState({
        canStream: IsStreamableVideo(props.file.fileExtension),
        isConverted: null,
        conversionStatus: "unknown"
    });
    
    if(!state.canStream && state.isConverted === null && state.conversionStatus === "unknown") {
        setState({ conversionStatus: "waiting"});
        API.CanStreamFile(props.creds, props.file.fileGuid).then(r => {
            console.log(r);
            setState({ isConverted: r, conversionStatus: null });
        });
    }

    useEffect(() => {
        let interval = null;
        if(state.conversionStatus !== "waiting" && state.conversionStatus !== "unknown" && state.conversionStatus !== "Success") {
            interval = setInterval(async () => {
                var state = await API.CheckConversionStatus(props.creds, props.file.fileGuid);
                console.log(state);
                setState({ conversionStatus: state, isConverted: state === "Success" });
            }, 5000);
        }
        else if(interval !== null) {
            clearInterval(interval);
            interval = null;
        }
        return () => clearInterval(interval);
    }, [state.conversionStatus, props.creds, props.file.fileGuid]);

    if(!state.canStream && !state.isConverted)
    {
        if(state.conversionStatus === null || state.conversionStatus === "NotConverting") {
            return (<div>
                <p>This file needs to be converted before it can be played</p>
                <button className="btn btn-primary" onClick={ConvertClicked}>Convert</button>
            </div>);
        }
        return (
            <div>
                <p>Video is converting. Current state: {state.conversionStatus}.</p>
                <p>This will take a while</p>
            </div>
        );
    }

    var ext = props.file.fileExtension;
    if(!IsStreamableVideo(ext))
        ext = "mp4";

    return (<VideoPlayer 
        url={`/api/Files/stream/${props.creds.UserName}/${props.creds.Password}/${props.file.fileGuid}`}
        ext={ext}
        startPosition={GetPlayPosition()}
        onPause={UpdateMetadataAsync}
        onDuration={CheckDuration}
        />);

    async function ConvertClicked() {
        setState({ conversionStatus: "starting..." });
        var status = await API.StartConvertingFile(props.creds, props.file.fileGuid);
        setState({ conversionStatus: status });
    }

    function GetMetadata() {
        if(props.file.metadata) {
            var metadata = JSON.parse(props.file.metadata);
            return metadata;
        }
        return null;
    }

    function GetPlayPosition() {
        var metadata = GetMetadata();
        if(metadata && metadata.lastPosition) {
            return metadata.lastPosition;
        }
        return null;
    }

    function CheckDuration(duration) {
        var metadata = GetMetadata();
        if(metadata === null) {
            metadata = { lastPosition: 0, views: 0, duration: null };
        }

        if(!metadata.duration) {
            metadata.duration = duration;
            var stringMetadata = JSON.stringify(metadata);
            props.file.metadata = stringMetadata;
            console.log(`Updating metadata: ${stringMetadata}`);
            API.SetFileMetadataAsync(props.creds, props.file.fileGuid, stringMetadata);
        }
    }

    async function UpdateMetadataAsync(lastPosition, finished) {
        var metadata = GetMetadata();
        if(metadata == null) {
            metadata = { lastPosition: lastPosition, views: 0, duration: null };
        }
        metadata.lastPosition = lastPosition;
        if(finished) {
            if(metadata.views) {
                metadata.views++;
            }
            else {
                metadata.views = 1;
            }
        }
        var stringMetadata = JSON.stringify(metadata);
        props.file.metadata = stringMetadata;
        console.log(`Updating metadata: ${stringMetadata}`);
        await API.SetFileMetadataAsync(props.creds, props.file.fileGuid, stringMetadata);
    }
}

function VideoPlayer(props) {
    
    var duration = null;

    return (<video ref={SetVideoRef} controls autoPlay style={{ maxWidth: "100%", maxHeight: "100vh" }} >
        <source src={props.url} type={`video/${props.ext}`}/>
    </video>);

    function SetVideoRef(ref) {
        if(ref != null)
        {
            ref.addEventListener('loadedmetadata', e => {
                duration = ref.duration;
                if(props.startPosition) {
                    ref.currentTime = props.startPosition;
                }
                if(props.onDuration) {
                    props.onDuration(duration);
                }
                ref.onpause = e => OnPause(ref);
                //ref.onplay = () => console.log("Play");
            }, false);
        }
    }

    function OnPause(Ref) {
        if(props.onPause) {
            if(Ref.currentTime >= (duration - 0.1)) {
                props.onPause(0, true);
            }
            else {
                props.onPause(Ref.currentTime, false);
            }
        }
    }
}
