import React, { Component } from 'react';
import { API } from './API';
import { GetFileType } from './HelperFunctions';
import { ImageView, TextDocView } from './FileControls';
import { VideoView } from './VideoView';
import { FileTile } from './FileTile';
import { NavHack } from './NavHack';
import { FileInfo } from './FileInfo';
import { SearchManager } from './SearchManager';

var emptyState = { fileInfo: null, moreLikeThis: null, navToLink: null };

export class FileView extends Component {

    constructor(props) {
        super(props);

        let fullUrl = window.location.href;
        let urlParts = fullUrl.split("/");
        this.fileID = urlParts[urlParts.length - 1];

        this.state = emptyState;
    }

    render() {

        let type = "";
        let fileView = null;
        if (this.state.fileInfo) {
            type = GetFileType(this.state.fileInfo.fileExtension);
            if (type === "image") {
                fileView = (<ImageView creds={this.props.creds} fileID={this.fileID} fileInfo={this.fileInfo} />)
            }
            else if (type === "text") {
                fileView = (<TextDocView creds={this.props.creds} fileID={this.fileID} />);
            }
            else if (type === "video")
            {
                fileView = (<VideoView creds={this.props.creds} file={this.state.fileInfo}/>);
            }
            else {
                fileView = (<div>Unknown file type</div>);
            }
        }

        return (
            <div>
                <div className="row">
                    <div className="col-12 col-md-3"><FileInfo creds={this.props.creds} fileInfo={this.state.fileInfo} /></div>
                    <div className="col-12 col-md-9" style={{ display: "flex", justifyContent: "center" }}>
                        <div>
                            <div><input type='button' value='prev' className='btn btn-secondary' disabled={!SearchManager.HasPrevious()} onClick={() => this.goPrev()} /></div>
                            <div><input type='button' value='next' className='btn btn-secondary' disabled={!SearchManager.HasNext()} onClick={() => this.goNext()} /></div>
                        </div>
                        {fileView}
                    </div>
                </div>
                {this.renderMoreLikeThis()}
                {this.state.navToLink != null ? (<NavHack link={this.state.navToLink} success={() => this.navHistoryUpdated() } />) : null}
            </div>
        );
    }

    renderMoreLikeThis() {
        var content = "Loading...";

        if(this.state.moreLikeThis !== null) {
            if(this.state.moreLikeThis.totalFiles === 0) {
                content = "None";
            } else {
                content = this.state.moreLikeThis.files.map(f => (<FileTile file={f} creds={this.props.creds} key={f.fileGuid}
                    isSelected={false} singleClick={() => this.goToFile(f)} doubleClick={() => this.goToFile(f)} />));
            }
        }

        return (
            <div>
                <div className="row">
                    <div className="col-12" style={{ fontWeight: "bold", fontSize: "20px" }}>More like this</div>
                </div>
                <div className="row">
                    {content}
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.loadData();
    }

    goToFile(file) {
        this.setState({ navToLink: `/file/${file.fileGuid}` });
    }

    navHistoryUpdated() {
        let fullUrl = window.location.href;
        let urlParts = fullUrl.split("/");
        this.fileID = urlParts[urlParts.length - 1];

        this.setState(emptyState);

        this.loadData();
    }

    async loadData() {
        API.GetFileInfo(this.props.creds, this.fileID, info => {
            this.setState({ fileInfo: info });
            var type = GetFileType(this.state.fileInfo.fileExtension);
            if(type !== "" && type !== "video") {
                //VideoView handles the view counter for videos
                this.updateViews();
            }
        });
        var moreLikeThis = await API.GetMoreLikeThis(this.props.creds, this.fileID);
        if(moreLikeThis !== null) {
            this.setState({ moreLikeThis: moreLikeThis });
        }
    }

    getMetadata(fileInfo) {
        if(fileInfo.metadata) {
            var metadata = JSON.parse(fileInfo.metadata);
            return metadata;
        }
        return null;
    }

    async updateViews() {
        var metaData = this.getMetadata(this.state.fileInfo);
        if(metaData === null) {
            metaData = { views: 0 };
        }
        if(!metaData.views) {
            metaData.views = 0;
        }
        metaData.views++;

        var stringMetadata = JSON.stringify(metaData);
        this.state.fileInfo.metadata = stringMetadata;
        console.log(`Updating metadata: ${stringMetadata}`);
        await API.SetFileMetadataAsync(this.props.creds, this.state.fileInfo.fileGuid, stringMetadata);
    }

    async goNext() {
        var next = await SearchManager.MoveNext(this.props.creds);
        if(next) {
            this.goToFile(next);
        }
    }

    async goPrev() {
        var prev = await SearchManager.MovePrevious(this.props.creds);
        if(prev) {
            this.goToFile(prev);
        }
    }
}