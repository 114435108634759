import React, { Component } from 'react';
import { API } from './API';
import { TagView } from './TagView';

export class TagEntryBox extends Component {

    constructor(props) {
        super(props);

        this.state = { tagText : "", suggestions: [], suggestedTagsWereFiltered: false };
        this.order = 1;
        this.currentOrder = 0;

        this.textChanged = this.textChanged.bind(this);
        this.buttonClicked = this.buttonClicked.bind(this);
        this.keyDown = this.keyDown.bind(this);
        this.suggestedTagClicked = this.suggestedTagClicked.bind(this);
    }

    render() {
        return (
            <div>
                <input className="form-control" type="text" onChange={this.textChanged} value={this.state.tagText} onKeyDown={this.keyDown} />
                {this.renderSuggestions()}
                <button className="btn btn-secondary" onClick={this.buttonClicked}>{this.props.buttonText}</button>
            </div>
        );
    }

    renderSuggestions() {
        if(this.props.showSuggestions && this.state.suggestions.length > 0) {

            var suggestions = this.state.suggestions;

            if(suggestions.length > 5) {
                suggestions = suggestions.slice(0, 5);
            }

            var tags = suggestions.map(tag => (
                <div key={tag.tag} style={{ backgroundColor: "lightgray", marginRight: "5px", borderRadius: "3px", marginLeft: "16px" }} className='col-auto'>
                    <TagView tag={tag} showPlus={true} tagClicked={this.suggestedTagClicked} />
                </div>));
            return (<div style={{ marginTop: "10px", marginBottom: "10px" }} className='row justify-content-left'>{tags}</div>);
        }
        return null;
    }

    textChanged(e) {
        this.setState({ tagText: e.target.value });
        this.loadSuggestions(e.target.value);
    }

    async loadSuggestions(tagText) {
        if(this.props.showSuggestions && tagText.length > 2) {
            var suggestions = await API.GetSuggestedTags(this.props.creds, tagText.split(" "), this.order++);
            if(suggestions && suggestions.allSuggestedTags) {
                if(suggestions.order < this.currentOrder) {
                    return;
                }
                this.currentOrder = suggestions.order;

                var returnedSuggestions = suggestions.allSuggestedTags;
                if(returnedSuggestions.length === 0) {
                    var tagParts = tagText.split(" ");
                    var lastTag = tagParts[tagParts.length - 1];
                    var filtered = this.state.suggestions.filter(s => s.tag.startsWith(lastTag) && s.tag !== lastTag);

                    if(filtered.length > 0) {
                        this.setState({ suggestions: filtered, suggestedTagsWereFiltered: true });
                        return;
                    }
                }

                this.setState({ suggestions: suggestions.allSuggestedTags, suggestedTagsWereFiltered: false });
            }
        }
        else {
            this.setState({ suggestions: [], suggestedTagsWereFiltered: false });
        }
    }

    keyDown(e) {
        if (e.key === "Enter") {
            this.buttonClicked();
        }
    }

    buttonClicked() {
        this.props.tagEntered(this.state.tagText);
        if (this.props.clearAfterEntry) {
            this.setState({ tagText: "" });
        }
    }

    suggestedTagClicked(tag) {
        var newTag = this.state.tagText;
        
        if(this.state.suggestedTagsWereFiltered) {
            newTag = newTag.split(" ").slice(0, -1).join(" ");
        }

        if(this.state.tagText.endsWith(" ")) {
            newTag += tag.tag;
        }
        else {
            newTag += " " + tag.tag;
        }
        this.setState({ tagText: newTag });
        this.loadSuggestions(newTag);
    }
}