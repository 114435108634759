import { API } from "./API";
import { SplitTags } from "./HelperFunctions";

var currentResults = null;
var currentOrder = "Weighted";

export class SearchManager {
    static async MakeSearch(Creds, SearchTerm, Page, Order) {
        const tags = SplitTags(SearchTerm);
        if(currentResults != null) {
            if(currentResults.searchTerm === SearchTerm && currentResults.results.currentPage === Page) {
                return currentResults.results;
            }
        }

        if(!Order) {
            Order = currentOrder;
        }

        var results = await API.SearchFilesAsync(Creds, tags, Page, Order);
        currentResults = { searchTerm: SearchTerm, results: results, displayIndex: 0 };
        return results;
    }

    static async SetDisplayIndex(Index) {
        console.log("Display index set to: " + Index);
        currentResults.displayIndex = Index;
    }

    static async GetCurrent() {
        if(currentResults != null && currentResults.results != null) {
            var localIndex = currentResults.displayIndex - ((currentResults.results.currentPage - 1) * currentResults.results.filesPerPage);
            return currentResults.results.files[localIndex];
        }
        return null;
    }

    static HasNext() {
        return currentResults != null && currentResults.results.totalFiles > currentResults.displayIndex + 1
    }

    static HasPrevious() {
        return currentResults != null && currentResults.displayIndex > 0;
    }

    static async MoveNext(Creds) {
        if(currentResults != null && currentResults.results != null) {
            currentResults.displayIndex = currentResults.displayIndex + 1;
            var localIndex = currentResults.displayIndex - ((currentResults.results.currentPage - 1) * currentResults.results.filesPerPage);

            if(localIndex >= currentResults.results.filesPerPage) {
                localIndex -= currentResults.results.filesPerPage;
                currentResults.results = await API.SearchFilesAsync(Creds, SplitTags(currentResults.searchTerm), currentResults.results.currentPage + 1, currentOrder);
            }

            var file = currentResults.results.files[localIndex];
            return file;
        }
        return null;
    }

    static async MovePrevious(Creds) {
        if(currentResults != null && currentResults.results != null) {
            currentResults.displayIndex = currentResults.displayIndex - 1;
            var localIndex = currentResults.displayIndex - ((currentResults.results.currentPage - 1) * currentResults.results.filesPerPage);

            if(localIndex < 0) {
                localIndex = currentResults.results.filesPerPage - 1;
                currentResults.results = await API.SearchFilesAsync(Creds, SplitTags(currentResults.searchTerm), currentResults.results.currentPage - 1, currentOrder);
            }

            return currentResults.results.files[localIndex];
        }
        return null;
    }
}