import React, { useState } from 'react';
import { API } from './API';

export function Logging(props) {
    
    const [state, updateState] = useState({ logs: null, filter: "", indices: null, selectedIndex: null });

    if(state.indices == null || state.logs == null) {
        if(state.indices == null)
            loadIndices();
        else
            loadLogs();
        return (<div>Loading...</div>);
    } else {
        var logsToShow = filterLogs(state.logs, state.filter);
        var alt = true;
        var logLines = logsToShow.map(log => {
            var jsonMessage = JSON.stringify(log.parsedMessage, null, 2);
            alt = !alt;
            return (<div key={log.id} style={{ backgroundColor: (alt ? "lightgray" : "transparent") }}><pre>{jsonMessage}</pre></div>);
        });

        var indexItems = state.indices.map(index => (<option key={index} value={index}>{index}</option>));

        return (
            <div>
                <div className='form-group'>
                    <select className='form-control' defaultValue={state.selectedIndex} onChange={e => setSelectedIndex(e.target.value)}>
                        {indexItems}
                    </select>
                </div>
                Filter: <input className="form-control" type="text" onChange={e => setState({filter: e.target.value})} value={state.filter} />
                <button className="btn btn-success" onClick={() => loadLogs()}>?</button>
                Logs found: {logsToShow.length};
                {logLines}
            </div>)
        ;
    }

    async function loadLogs() {
        var logs = await API.GetLogs(props.creds, null, state.selectedIndex, null, null);
        console.log(logs);
        logs.forEach(log => log.parsedMessage = JSON.parse(log.message));
        setState({ logs: logs });
    }

    async function loadIndices() {
        var indices = await API.GetLogIndices(props.creds);
        console.log(indices);
        setState({ indices: indices, selectedIndex: indices[0] });
    }

    async function setSelectedIndex(index) {
        var logs = await API.GetLogs(props.creds, null, index, null, null);
        console.log(logs);
        logs.forEach(log => log.parsedMessage = JSON.parse(log.message));
        setState({ selectedIndex: index, logs: logs });
    }

    function setState(newState) {
        var updatedState = { ...state, ...newState };
        updateState(updatedState);
    }

    function filterLogs(logs, filter) {
        var pipeline = buildFilterPipeline(filter);
        console.log(`Applying ${pipeline.length} filter steps`)
        var filteredLogs = logs;
        var pipelineIndex = 0;
        while(filterLogs.length > 0 && pipelineIndex < pipeline.length) {
            filteredLogs = pipeline[pipelineIndex++](filteredLogs);
        }
        return filteredLogs;
    }

    function buildFilterPipeline(filter) {
        var pipeline = [logs => logs.sort((a,b) => a.parsedMessage.timestamp < b.parsedMessage.timestamp)];
        if(filter === "") {
            return pipeline;
        }
        var filterParts = splitQuoteAware(filter, "|");
        filterParts.forEach(part => {
            if(part === "reverse") {
                pipeline.push(logs => logs.slice().reverse());
            } else if (part.startsWith("select")) {
                var subParts = splitQuoteAware(part, " ");
                if(subParts.length > 1) {
                    pipeline.push(buildSelectFilter(subParts));
                } 
            } else if (part === "count") {
                pipeline.push(logs => {
                    var countObj = { count: logs.length };
                    return [{ id: 0, index: "filter", level: 0, timestamp: "", message: JSON.stringify(countObj), parsedMessage: countObj}];
                });
            } else {
                var mustContain = splitQuoteAware(part, " ");
                mustContain.forEach(mc => pipeline.push(logs => logs.filter(l => l.message.includes(mc))));
            }
        });
        return pipeline;
    }

    function buildSelectFilter(subParts) {
        return logs => logs.map(log => {
            var selectedMessage = {};
            for(var i = 1;i < subParts.length;++i) {
                if(log.parsedMessage[subParts[i]]) {
                    selectedMessage[subParts[i]] = log.parsedMessage[subParts[i]];
                }
            }
            return { id: log.id, index: log.index, level: log.level, timestamp: log.timestamp, message: log.message, parsedMessage: selectedMessage};
        })
    }

    function splitQuoteAware(string, splitOn) {
        var parts = [];
        var inQuotes = false;
        var currentSub = "";
        for(var i = 0;i < string.length;++i) {
            var char = string[i];
            if (inQuotes) {
                if (char === "\\" && string.length > i + 1 && string[i+ 1] === "\"") {
                    currentSub += "\"";
                    i++;
                } else if (char === "\"") {
                    inQuotes = false;
                } else {
                    currentSub += char;
                }
            } else {
                if(char === "\"") {
                    inQuotes = true;
                } else if (char === splitOn) {
                    currentSub = currentSub.trim();
                    if(currentSub.length > 0) {
                        parts.push(currentSub);
                        currentSub = "";
                    }
                } else {
                    currentSub += char;
                }
            }
        }
        currentSub = currentSub.trim();
        if(currentSub.length > 0) {
            parts.push(currentSub);
        }
        return parts;
    }
}