import React from "react";

export function TagView(props) {
    return (
    <div className="col col-md-12" style={{ cursor: "pointer" }} onClick={TagClicked}>
        {props.tag.tag}({props.tag.files})
        {props.showPlus ? (<span style={{ fontWeight: "bold" }}>+</span>) : null}
    </div>);

    function TagClicked() {
        if(props.tagClicked)
            props.tagClicked(props.tag);
    }
}
