import React from 'react';

export function ErrorMessage(props) {
    return (
        <div className="alert alert-warning">
            <strong className="">Error:</strong> {props.message}
        </div>
    );
}

//export default ErrorMessage;