import React, { Component } from "react";
import { API } from './API';

export class ManageTags extends Component {

    constructor(props) {
        super(props);

        this.state ={ allTags: [], mergeTagFrom: "", mergeTagTo: "" };

        this.mergeTags = this.mergeTags.bind(this);
    }

    render() {
        return (
            <div>
                <h4>Merge tags</h4>
                <div className="form-inline mr-2">
                    <div className="form-group">
                        Merge
                    </div>
                    <div className="form-group ml-2">
                        {this.renderTagDropList(e => this.setState({ mergeTagFrom: e.target.value }), this.state.mergeTagFrom)}
                    </div>
                    <div className="form-group ml-2">
                        into
                    </div>
                    <div className="form-group ml-2">
                        {this.renderTagDropList(e => this.setState({ mergeTagTo: e.target.value }), this.state.mergeTagTo)}
                    </div>
                    <div className="form-group ml-2">
                        <button className="btn btn-primary" onClick={this.mergeTags}>Merge</button>
                    </div>
                </div>
                <hr />
            </div>
        );
    }

    loadTags() {
        API.GetTagsForUser(this.props.creds, tags => {
            this.setState({ allTags: tags });
        });
    }

    renderTagDropList(onChange, DefaultValue) {
        let tagList = this.state.allTags.map(tag => (<option value={tag.tag} key={tag.tag}>{tag.tag} ({tag.files})</option>));
        return (<select className="form-control" onChange={onChange} defaultValue={DefaultValue}><option value="">Select a tag</option>{tagList}</select>);
    }

    componentDidMount() {
        this.loadTags();
    }

    mergeTags() {
        if (this.state.mergeTagFrom !== "" && this.state.mergeTagTo !== "") {
            console.log("Merging: " + this.state.mergeTagFrom + " into " + this.state.mergeTagTo);
            API.MergeTags(this.props.creds, this.state.mergeTagFrom, this.state.mergeTagTo, r => {
                if (r) {
                    this.setState({ mergeTagFrom: "", mergeTagTo: "" });
                    this.loadTags();
                }
            });
        }
    }
}