function MakeRequest(Url, OnSuccess, Creds, Method, RespType, Body, OnFailure) {
    var xhr = new XMLHttpRequest();
    xhr.open(Method || 'GET', Url, true);
    xhr.responseType = RespType || 'json';
    if (Creds) {
        //add authentication header
        xhr.setRequestHeader("XAUTH", "UPW " + Creds.UserName + "," + Creds.Password);
    }
    if (Body) {
        xhr.setRequestHeader("Content-Type", "application/json");
    }
    xhr.onload = function () {
        var status = xhr.status;
        if (status === 200 && OnSuccess) {
            OnSuccess(xhr.response);
        }
        else if (status === 204 && OnSuccess) {
            OnSuccess(null);
        }
        else if (OnFailure){
            OnFailure(Error(`Error calling: ${Url}: ${xhr.statusText} (${xhr.status}): ${xhr.response}`));
        }
    };
    xhr.onerror = function() {
        if(OnFailure) {
            OnFailure(Error("There was a network error"));
        }
    }
    if(Body) {
        xhr.send(JSON.stringify(Body));
    }
    else {
        xhr.send();
    }
}

function MakeRequestAsync(Url, Creds, Method, RespType, Body) {
    return new Promise((resolve, reject) => {
        MakeRequest(Url, result => resolve(result), Creds, Method, RespType, Body, err => reject(err));
    });
}

export class API {
    static Authenticate(UserName, Password, OnSuccess) {
        MakeRequest(`/api/Users/Authenticate?UserName=${encodeURI(UserName)}&Password=${encodeURI(Password)}`, OnSuccess);
    }

    static GetFilesForUser(Creds, Page, OnResult) {
        MakeRequest(`/api/Files/AllFiles?Count=16&Page=${Page}`, OnResult, Creds);
    }

    static GetFileInfo(Creds, FileID, OnResult) {
        MakeRequest(`/api/Files/info/${FileID}`, OnResult, Creds);
    }

    static GetFileInfoAsync(Creds, FileID) {
        return MakeRequestAsync(`/api/Files/info/${FileID}`, Creds);
    }

    static AddTagToFile(Creds, FileID, Tag, OnResult) {
        MakeRequest(`/api/Tags/addtofile/${FileID}?Tag=${encodeURI(Tag)}`, OnResult, Creds, "PUT");
    }

    static AddTagToFileAsync(Creds, FileID, Tag) {
        return MakeRequestAsync(`/api/Tags/addtofile/${FileID}?Tag=${encodeURI(Tag)}`, Creds, "PUT");
    }

    static GetTagsForUser(Creds, OnResult) {
        MakeRequest(`/api/Tags/userstags`, OnResult, Creds);
    }

    static SearchFiles(Creds, Tags, Page, Order, OnResult) {
        if (Tags.length === 0) {
            Tags = [','];
        }
        MakeRequest(`/api/Files/search?Tags=${Tags.map(t => encodeURI(t)).join(',')}&Count=16&Page=${Page}&OrderBy=${Order}`, OnResult, Creds);
    }

    static SearchFilesAsync(Creds, Tags, Page, Order) {
        if (Tags.length === 0) {
            Tags = [','];
        }
        return MakeRequestAsync(`/api/Files/search?Tags=${Tags.map(t => encodeURI(t)).join(',')}&Count=16&Page=${Page}&OrderBy=${Order}`, Creds);
    }

    static GetTagsForSearch(Creds, Tags, OnResult) {
        MakeRequest(`/api/Tags/searchtags?Tags=${Tags.map(t => encodeURI(t)).join(',')}`, OnResult, Creds);
    }

    static GetTagsForSearchAsync(Creds, Tags) {
        return MakeRequestAsync(`/api/Tags/searchtags?Tags=${Tags.map(t => encodeURI(t)).join(',')}`, Creds);
    }

    static GetSmallFile(Creds, FileID, ResultType, OnResult) {
        MakeRequest(`/api/Files/small/${FileID}`, OnResult, Creds, "GET", ResultType);
    }

    static MergeTags(Creds, TagFrom, TagTo, OnResult) {
        MakeRequest(`/api/Tags/mergetags?TagFrom=${encodeURI(TagFrom)}&TagTo=${encodeURI(TagTo)}`, OnResult, Creds, "PATCH");
    }

    static GetFullFile(Creds, FileID, ResultType, OnResult) {
        MakeRequest(`/api/Files/${FileID}`, OnResult, Creds, "GET", ResultType);
    }

    static SetFileRating(Creds, FileID, Rating, OnResult) {
        MakeRequest(`/api/Files/setrating/${FileID}?Rating=${Rating}`, OnResult, Creds, 'PATCH');
    }

    static SetFileRatingAsync(Creds, FileID, Rating) {
        return MakeRequestAsync(`/api/Files/setrating/${FileID}?Rating=${Rating}`, Creds, "PATCH");
    }

    static AddFileFromLink(Creds, Link, OnResult) {
        MakeRequest(`/api/Files/addfromlink?Link=${encodeURI(Link)}`, OnResult, Creds, 'PUT');
    }

    static SetFileDisplayName(Creds, FileID, DisplayName, OnResult) {
        MakeRequest(`/api/Files/setfiledisplayname/${FileID}?DisplayName=${DisplayName}`, OnResult, Creds, "PATCH");
    }

    static SetFileDisplayNameAsync(Creds, FileID, DisplayName) {
        return MakeRequestAsync(`/api/Files/setfiledisplayname/${FileID}?DisplayName=${DisplayName}`, Creds, "PATCH");
    }

    static SetFileMetadataAsync(Creds, FileID, Metadata) {
        return MakeRequestAsync(`/api/Files/setfilemetadata/${FileID}?Metadata=${encodeURI(Metadata)}`, Creds, "PATCH");
    }

    static AddCollection(Creds, CollectionName, OnResult) {
        MakeRequest(`api/Collections/add?Name=${CollectionName}`, OnResult, Creds, 'PUT');
    }

    static UploadFileChunk(Creds, UploadObject) {
        return MakeRequestAsync(`api/Files/upload`, Creds, "POST", "text/text", UploadObject);
    }

    static StartConversionJob(Creds) {
        return MakeRequestAsync(`api/Files/updatev1tov2`, Creds, "GET", 'text/text');
    }

    static GetIsUpdateTaskRunning(Creds) {
        return MakeRequestAsync(`api/Files/updatetaskisrunningforuser`, Creds, "GET", "text/text");
    }

    static GetSuggestedTags(Creds, Tags, Order) {
        return MakeRequestAsync(`/api/Tags/getsuggestedtags?Tags=${Tags.map(t => encodeURI(t)).join(',')}&Order=${Order}`, Creds);
    }

    static GetAllCollections(Creds) {
        return MakeRequestAsync('/api/Collections/all', Creds);
    }

    static AddFilesToCollection(Creds, CollectionID, FileIDs) {
        return MakeRequestAsync(`api/Collections/${CollectionID}/addfiles`, Creds, "POST", null, FileIDs);
    }

    static SetCollectionCoverImage(Creds, CollectionID, ImageID) {
        return MakeRequestAsync(`api/Collections/${CollectionID}/setcoverimage?ImageID=${ImageID}`, Creds, "PATCH");
    }

    static CanStreamFile(Creds, FileID) {
        return MakeRequestAsync(`api/Files/canstream/${FileID}`, Creds);
    }

    static StartConvertingFile(Creds, FileID) {
        return MakeRequestAsync(`api/Files/startconversion/${FileID}`, Creds, "GET", "text/text");
    }

    static CheckConversionStatus(Creds, FileID) {
        return MakeRequestAsync(`api/Files/checkconversionstatus/${FileID}`, Creds, "GET", "text/text");
    }

    static GetMoreLikeThis(Creds, FileID) {
        return MakeRequestAsync(`api/Files/morelikethis/${FileID}`, Creds, "GET");
    }

    static GetLogs(Creds, Level, Index, StartTime, EndTime) {
        var url = `api/Logging/search?`;
        if (Level != null)
            url += `Level=${Level}&`;
        if (Index != null)
            url += `Index=${Index}&`;
        if (StartTime != null)
            url += `StartTime=${StartTime}&`;
        if (EndTime != null)
            url += `EndTime=${EndTime}&`;
        url = url.substring(0, url.length - 1);

        return MakeRequestAsync(url, Creds);
    }

    static GetLogIndices(Creds) {
        return MakeRequestAsync(`api/Logging/indices`, Creds);
    }
}
