import React, { Component } from 'react';
import { API } from './API';

export function ImageView(props) {
    return (<img src={`/api/Files/full/${props.creds.UserName}/${props.creds.Password}/${props.fileID}`} style={{ maxWidth: "100%", maxHeight: "90vh" }} alt={props.fileInfo ? props.fileInfo.fullFileName : "loading..."} />);
}

export class TextDocView extends Component {

    constructor(props) {
        super(props);

        this.state = { text: null };
    }

    render() {
        return (
            <div style={{ wordBreak: "break-word" }}>{ this.state.text || "...loading" }</div>
        );
    }

    componentDidMount() {
        this.loadText();
    }

    loadText() {
        API.GetFullFile(this.props.creds, this.props.fileID, "text", text => {
            this.setState({ text: text });
        });
    }
}
