import React, { Component } from "react";
import { API } from './API';
import { GetFileType } from './HelperFunctions';
import { TagEntryBox } from './TagEntryBox';

export class QuickTag extends Component {

    constructor(props) {
        super(props);

        this.state = { file: null, name: null, rating: null, orderBy: "Random" };
        this.tagAdded = this.tagAdded.bind(this);
        this.onNameChanged = this.onNameChanged.bind(this);
        this.ratingChanged = this.ratingChanged.bind(this);
    }

    render() {
        if (this.state.file == null) {
            return (<div>No files</div>);
        }
        else {
            return (
                <div>
                    <div className="form-inline mr-2">
                        <div className="form-group">
                            <select className="form-control" defaultValue={this.state.orderBy} onChange={e => this.setState({ orderBy: e.target.value })}>
                            <option value="Random">Random</option>
                                <option value="NewestFirst">Newest First</option>
                                <option value="OldestFirst">Oldest First</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-outline-success" onClick={() => this.loadFirstUntaggedFile()}>Something else?</button>
                        </div>
                    </div>
                    <div style={{ fontWeight: "bold" }}>{this.state.file.fullFileName}</div>
                    {this.renderFileDisplay()}
                    <div className="col-12" style={{ fontWeight: "bold" }}>Name</div>
                    <div className="col-12">
                        <input type="text" className="form-control" value={this.state.name || ""} onChange={this.onNameChanged}/>
                    </div>
                    <div className="col-12" style={{ fontWeight: "bold" }}>Rating</div>
                    <input className="form-control" type="range" onChange={this.ratingChanged} value={this.state.rating || 0} min="0" max="10" step="0.1" />
                    <div className="col-12" style={{ fontWeight: "bold" }}>Tags</div>
                    <TagEntryBox buttonText="Add" clearAfterEntry="true" tagEntered={this.tagAdded} creds={this.props.creds} showSuggestions={true} />
                    <button className="btn btn-danger" onClick={() => this.tagAdded("delete")}>Delete</button>
                </div>
            );
        }
    }

    renderFileDisplay() {
        let fileType = GetFileType(this.state.file.fileExtension);
        if (fileType === "image") {
            return (<img src={`/api/files/full/${this.props.creds.UserName}/${this.props.creds.Password}/${this.state.file.fileGuid}`} style={{ maxWidth: "100%", maxHeight: "70vh" }} alt={this.state.file.fullFileName} />);
        }
        else if (fileType === "text") {
            return (<div>This is a text file</div>);
        }
        else if (fileType === 'video') {
            return (<video key={this.state.file.fileGuid} controls autoPlay style={{ maxWidth: "100%" }} ><source src={`/api/files/stream/${this.props.creds.UserName}/${this.props.creds.Password}/${this.state.file.fileGuid}`} type="video/mp4" /></video>);
        }
    }

    loadFirstUntaggedFile() {
        API.SearchFiles(this.props.creds, ['no-tags'], 1, this.state.orderBy, files => {
            if (files.files.length > 0) {
                this.setState({ file: files.files[0], name: files.files[0].displayName, rating: files.files[0].rating });
            }
            else {
                this.setState({ file: null, name: null, rating: null });
            }
        });
    }

    componentDidMount() {
        this.loadFirstUntaggedFile();
    }

    async tagAdded(Tag) {
        var calls = [];
        if(Tag != null)
            calls.push(API.AddTagToFileAsync(this.props.creds, this.state.file.fileGuid, Tag));
        if(this.state.name != null && this.state.name !== "")
            calls.push(API.SetFileDisplayNameAsync(this.props.creds, this.state.file.fileGuid, this.state.name));
        if(this.state.rating != null)
            calls.push(API.SetFileRatingAsync(this.props.creds, this.state.file.fileGuid, this.state.rating));

        var results = await Promise.all(calls);

        if(results) {
            this.loadFirstUntaggedFile();
        }
    }

    onNameChanged(e) {
        this.setState({ name: e.target.value });
    }

    ratingChanged(e) {
        this.setState({ rating: e.target.value });
    }
}
