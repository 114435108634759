import React, { useState } from 'react';
import { TagEntryBox } from './TagEntryBox';
import { API } from './API';
import { ValueViewEdit } from './ValueViewEdit';
import { Link } from "react-router-dom";

export function FileInfo(props) {
    console.log(props);

    const [rating, setRating] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [tags, setTags] = useState(null);

    if (props.fileInfo != null) {
        setIfNull(rating, props.fileInfo.rating, setRating);
        setIfNull(fileName, props.fileInfo.displayName, setFileName);
        setIfNull(tags, props.fileInfo.tags, setTags);
        var viewCount = 0;
        var metaData = getMetadata(props.fileInfo);
        if(metaData !== null && metaData.views != null) {
            viewCount = metaData.views;
        }
        return (
            <div className="row">
                <div className="col-12" style={{ fontWeight: "bold" }}>Name</div>
                <div className="col-12" style={{ wordBreak: "break-word" }}><ValueViewEdit value={fileName} valueChanged={fileDisplayNameChanged}/></div>
                <div className="col-12" style={{ fontWeight: "bold" }}>File Name</div>
                <div className="col-12" style={{ wordBreak: "break-word" }}>{props.fileInfo.fullFileName}</div>
                {props.fileInfo.type !== "Collection" ? (<div className="col-12" style={{ fontWeight: "bold" }}>SHA256</div>) : null}
                {props.fileInfo.type !== "Collection" ? (<div className="col-12" style={{ wordBreak: "break-word" }}>{props.fileInfo.fileHash}</div>) : null}
                <div className="col-12" style={{ fontWeight: "bold"}}>Views</div>
                <div className="col-12" style={{ wordBreak: "break-word" }}>{viewCount}</div>
                <div className="col-12" style={{ fontWeight: "bold" }}>Rating</div>
                <div className="col-12">
                    {rating || 0}
                    <input className="form-control" type="range" onChange={ratingChanged} onMouseUp={ratingReleased} onTouchEnd={ratingReleased} value={rating || 0} min="0" max="10" step="0.1" />
                </div>
                <div className="col-12" style={{ fontWeight: "bold" }}>Tags</div>
                <div className="col-12">
                    <TagEntryBox buttonText="Add" clearAfterEntry="true" tagEntered={tagAdded} creds={props.creds} showSuggestions={tags === null || tags.length === 0} />
                    <SimpleTagsList tags={tags} />
                </div>
            </div>
        );
    }
    return (<div></div>);

    function ratingChanged(e) {
        setRating(e.target.value);
    }

    function ratingReleased(e) {
        API.SetFileRating(props.creds, props.fileInfo.fileGuid, rating);
    }

    async function fileDisplayNameChanged(newName) {
        var r = await API.SetFileDisplayNameAsync(props.creds, props.fileInfo.fileGuid, newName);
        console.log(r);
        if (r) {
            setFileName(newName);
        }
    }

    function getMetadata(fileInfo) {
        if(fileInfo.metadata) {
            var metadata = JSON.parse(fileInfo.metadata);
            return metadata;
        }
        return null;
    }

    async function tagAdded(Tag) {
        var r = await API.AddTagToFileAsync(props.creds, props.fileInfo.fileGuid, Tag)
        if (r) {
            var updatedInfo = await API.GetFileInfoAsync(props.creds, props.fileInfo.fileGuid);
            console.log(updatedInfo);
            setTags(updatedInfo.tags);
        }
    }

    function setIfNull(value, shouldBe, set) {
        if(value === null && shouldBe != null) {
            set(shouldBe);
        }
    }
}

export function SimpleTagsList(props) {
    if (props.tags != null) {
        return props.tags.map(tag => (<div key={tag.tag}><Link to={`/?tags=${tag.tag}`}>{tag.tag}({tag.files})</Link></div>));
    }
    return (<div></div>);
}
