import React from "react";
import { useHistory } from "react-router-dom";

var lastPush = null;

export function NavHack(props) {
    const history = useHistory();

    function DoLinky() {
        if(lastPush !== props.link) {
            history.push(props.link);
            lastPush = props.link;
            if(props.success) {
                props.success(props.link);
            }
            setTimeout(() => lastPush = null, 500);
        }
    }

    setTimeout(() => DoLinky(), 0);

    return <div></div>
}