export function GetQueryString() {
    var urlParts = window.location.href.split("?");
    var result = [];
    if (urlParts.length === 2) {
        var queryString = urlParts[1];
        var queryParts = queryString.split("&");
        queryParts.forEach(qp => {
            var paramParts = qp.split("=");
            if (paramParts.length === 2) {
                result[paramParts[0]] = paramParts[1];
            }
        });
    }
    return result;
}

let imagefiles = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
let textFiles = ["txt"];
let streamableVideos = ["mp4", "webm", "ogg"];
let videoFiles = ["mp4", "webm", "ogg", "wmv", "avi", "aac", "asf", "flv", "mov", "m4v", "3gp", "mpg"];

export function GetFileType(FileExt) {
    if(!FileExt)
        return "unknown";
    if (imagefiles.includes(FileExt.toLowerCase())) {
        return "image";
    }
    else if (textFiles.includes(FileExt.toLowerCase())) {
        return "text";
    }
    else if (videoFiles.includes(FileExt.toLowerCase())) {
        return "video";
    }
    return "unknown";
}

export function IsStreamableVideo(FileExt) {
    return streamableVideos.includes(FileExt.toLowerCase());
}

export function SplitTags(Tags) {
    var resultTags = [];
    var currentTag = "";
    var withinQuotes = false;
    for(var i = 0;i < Tags.length;++i){
        var c= Tags[i];
        if(c === '"') {
            withinQuotes = !withinQuotes;
            currentTag += '"';
        }
        else if(c === " " && !withinQuotes) {
            resultTags.push(currentTag);
            currentTag = "";
        }
        else {
            currentTag += c;
        }
    }
    if(currentTag.length > 0) {
        resultTags.push(currentTag);
    }
    return resultTags;
}
