import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { Login } from './components/Login';
import { FileView } from './components/FileView';
import { QuickTag } from './components/QuickTag';
import { ManageTags } from './components/ManageTags';
import { Logging } from './components/Logging';

import './custom.css';

export default class App extends Component {
    static displayName = App.name;
    static OnLogin = null;
    static Creds = null;
    static Page = 1;

    constructor(props) {
        super(props);

        this.state = { creds: null };

        this.OnLogin = this.OnLogin.bind(this);
        App.OnLogin = this.OnLogin;
        this.OnPageChanged = this.OnPageChanged.bind(this);
    }

    render() {

        if (this.state.creds == null) {
            return (<Layout><Login onlogin={this.OnLogin} /></Layout>);
        }
        else {
            return (
                <Layout>
                    <Route exact path='/' render={props => <Home creds={this.state.creds} page={App.Page} onPageChanged={this.OnPageChanged} />} />
                    <Route path='/counter' component={Counter} />
                    <Route path='/fetch-data' component={FetchData} />
                    <Route path='/image' render={props => <FileView creds={this.state.creds} />} />
                    <Route path='/file' render={props => <FileView creds={this.state.creds} />} />
                    <Route path='/quicktag' render={props => <QuickTag creds={this.state.creds} />} />
                    <Route path='/managetags' render={props => <ManageTags creds={this.state.creds} />} />
                    <Route path='/logging' render={props => <Logging creds={this.state.creds} /> } />
                </Layout>
            );
        }
    }

    OnLogin(Creds) {
        App.Creds = Creds;
        this.setState({ creds: Creds });
    }

    OnPageChanged(NewPage) {
        App.Page = NewPage;
    }
}

//https://developer.mozilla.org/en-US/docs/Web/API/FileReader
